import { axiosInstance } from './index';
import { useMutation } from 'react-query';

export interface IBookmarkParams {
  action: 'set' | 'unset' | 'reorder';
  dappId: string;
}

const updateBookmark = async (params: IBookmarkParams) => {
  const { data } = await axiosInstance.post<{ success: boolean }>(
    `/dapps/bookmarks`,
    params
  );
  return data;
};

const useUpdateBookmark = () => {
  return useMutation((params: IBookmarkParams) => updateBookmark(params), {
    retry: false,
  });
};

export default useUpdateBookmark;
