import React from 'react';
import dataNoneImg from 'assets/images/nodata_img.svg';
import { DAppItem, DAppItemProps } from 'core/components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/selector';
import useUpdateRecentSearch from '../../hooks/queries/useUpdateRecentSearch';
import { useQueryClient } from 'react-query';
import useBookmarks from '../../hooks/queries/useBookmarks';

type SearchResultsProps = {
  keyword?: string;
  items: DAppItemProps[];
};

function SearchResults(props: SearchResultsProps) {
  const { t } = useTranslation('search');
  const { items, keyword } = props;

  const queryClient = useQueryClient();
  const user = useSelector(selectUser);
  const recentSearchMutation = useUpdateRecentSearch();
  const { data: bookmarksData } = useBookmarks(user?.userId);

  const onSearch = async (
    e: React.MouseEvent<HTMLLIElement>,
    searchDappId: number
  ) => {
    e.preventDefault();

    if (user) {
      recentSearchMutation.mutate(
        {
          action: 'set',
          word: keyword,
          dappId: String(searchDappId),
        },
        {
          onSettled: () => queryClient.invalidateQueries('/dapps/search'),
        }
      );
    }
  };

  return (
    <main className="layout-contents-container page-search">
      <section className="section-contents">
        {items?.length > 0 && (
          <h1 className="title">{items && <>{t('searchResult')}</>}</h1>
        )}
        <ul className="list-dapps">
          {items?.length === 0 && (
            <li className="none">
              <img src={dataNoneImg} alt="data none img" />
              <p>{t('emptyResult')}</p>
            </li>
          )}
          {items.map((_item: DAppItemProps, _index: number) => {
            return (
              <li
                key={_index}
                onClick={e => {
                  onSearch(e, _item.dapp.dappId);
                }}
              >
                <DAppItem
                  type="list"
                  {..._item}
                  bookmark={
                    bookmarksData &&
                    bookmarksData.bookmarks.includes(_item.dapp.dappId)
                  }
                  rank={undefined}
                />
              </li>
            );
          })}
          {/* # google search UI */}
          {/*<li className="none">*/}
          {/*  <span className="logo">*/}
          {/*    <i className="logo-google" />*/}
          {/*  </span>*/}
          {/*  <a*/}
          {/*    href={`https://www.google.com/search?q=${keyword}`}*/}
          {/*    target="_blank"*/}
          {/*    rel="noopener noreferrer"*/}
          {/*  >*/}
          {/*    <span className="google-search-navigation">*/}
          {/*      Search &quot;{keyword}&quot;*/}
          {/*    </span>*/}
          {/*  </a>*/}
          {/*</li>*/}
        </ul>
      </section>
    </main>
  );
}

export default SearchResults;
