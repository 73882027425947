import Fuse from 'fuse.js';
import { IDapp } from 'interfaces/dapp';
import { AssetPrice } from 'hooks/queries/useTokenPrice';
import isEqual from 'lodash/isEqual';

export const mapDappIdToDapps = (
  dapps: IDapp[],
  dappIds: number[],
  limit?: number
) => {
  const idx = Fuse.createIndex(['dappId'], dapps);
  const fuse = new Fuse<IDapp>(
    dapps,
    {
      useExtendedSearch: true,
      keys: ['dappId'],
    },
    idx
  );
  const res: IDapp[] = [];
  Array.from(dappIds).map(id => {
    if (isEqual(res.length, limit)) return res;
    const searchRes = fuse.search(`=${id}`)[0];
    if (searchRes) {
      res.push(fuse.search(`=${id}`)[0].item);
    }
  });
  return res;
};

export function getLocalState<T>(key: string, initialValue: T): T {
  if (typeof window === 'undefined') return initialValue;

  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (e) {
    console.error(e);
    return initialValue;
  }
}

export function setLocalState<T>(key: string, value: T) {
  try {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (e) {
    console.error(e);
  }
}

// export function saveToSessionStorage<T>(key: string, value: T) {
//   try {
//     if (typeof window !== 'undefined') {
//       window.sessionStorage.setItem(key, JSON.stringify(value));
//     }
//   } catch (e) {
//     console.error(e);
//   }
// }

// export function getFromSessionStorage<T>(key: string, initialValue: T): T {
//   if (typeof window === 'undefined') return initialValue;
//
//   try {
//     const item = window.sessionStorage.getItem(key);
//     return item ? JSON.parse(item) : initialValue;
//   } catch (e) {
//     console.error(e);
//     return initialValue;
//   }
// }

// e.g: $2,809.34 (+12.35%)
export function formatFiatPrice(
  language = 'en-US',
  baseCurrency = 'USD',
  assetPrice: AssetPrice
): string {
  try {
    const { quote } = assetPrice;
    const assetPriceItem = quote[baseCurrency];

    if (typeof assetPriceItem === 'undefined') return '';

    const { price, percentChange24h } = assetPriceItem;
    const formattedPrice = Intl.NumberFormat(language, {
      style: 'currency',
      currency: baseCurrency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(price));

    return `${formattedPrice} (${percentChange24h.toFixed(2)}%)`;
  } catch (e) {
    console.error(e);
    return '';
  }
}

export function showBottomBar() {
  if (typeof bwi !== 'undefined') {
    bwi?.showBottomBar();
  }
}

export function hideBottomBar() {
  if (typeof bwi !== 'undefined') {
    bwi?.hideBottomBar();
  }
}

export function getBaseCurrency() {
  if (typeof bwi !== 'undefined') {
    return bwi?.getBaseCurrency();
  }
}
