import { config } from 'config';

import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { IUser } from 'interfaces/user';

const app = initializeApp(config.FIREBASE);
const analytics = getAnalytics(app);

export const dispatchGAExecute = async (user: IUser | null, dappId: string) => {
  try {
    if (!user) return;
    await logEvent(analytics, 'APIEVENT', {
      eventType: 'EXECUTE',
      user: user.userId,
      reqBody: `dappId=${dappId}`,
    });
  } catch (e) {
    console.error(e);
  }
};

export const dispatchGACategory = async (
  user: IUser | null,
  dappCatName: string
) => {
  try {
    if (!user) return;
    await logEvent(analytics, 'APIEVENT', {
      eventType: 'CATEGORY',
      user: user.userId,
      reqBody: `dappCatName=${dappCatName}`,
    });
  } catch (e) {
    console.error(e);
  }
};

export const dispatchGASearch = async (user: IUser | null) => {
  try {
    if (!user) return;
    await logEvent(analytics, 'APIEVENT', {
      eventType: 'SEARCH',
      user: user.userId,
    });
  } catch (e) {
    console.error(e);
  }
};

export default analytics;
