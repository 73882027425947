export const config = {
  ENV_NAME: process.env.REACT_APP_ENV_NAME || '',
  BASE_URL: process.env.REACT_APP_API_BASE_URL || '',
  EVENT_BASE_URL: process.env.REACT_APP_EVENT_BASE_URL || '',
  ASSET_BASE_URL: process.env.REACT_APP_API_ASSET_BASE_URL || '',
  S3_ENDPOINT: process.env.REACT_APP_S3_ENDPOINT || '',
  FIREBASE: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAEG_BUCKET || '',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
    appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
  },
};
