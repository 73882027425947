import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as en from './en';
import * as ko from './ko';
import { config } from 'config';
import { Language } from 'types/language';
import { getLocalState } from 'utils';
import { initialState } from 'store/locale/localeSlice';

const resources: Resource = {
  en: {
    ...en,
  },
  ko: {
    ...ko,
  },
} as const;

const languages: Language[] = ['en', 'ko'];

i18next.use(initReactI18next).init({
  resources: resources,
  lng: getLocalState('localeState', initialState).locale || 'ko',
  supportedLngs: languages,
  debug: config.ENV_NAME === 'development',
  fallbackLng: {
    default: ['en'],
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export const getAllLanguages = () => {
  return Object.keys(i18next.services.resourceStore.data);
};

export default i18next;
