import React, { useRef, useLayoutEffect } from 'react';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const GuideMyDApp: React.FC = props => {
  const guideRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const container = document.querySelector('.layout-wrap');
    if (guideRef.current !== undefined) {
      ScrollTrigger.create({
        trigger: container,
        markers: false,
        start: `top top`,
        onEnter: () => {
          guideRef.current?.classList.add('hide');
        },
      });

      setTimeout(() => {
        if (guideRef.current !== undefined) {
          guideRef.current?.classList.add('hide');
        }
      }, 5000);
    }
  }, [guideRef]);

  return (
    <div className="mydapp_guide" ref={guideRef}>
      <p className="mydapp_message">{props.children}</p>
    </div>
  );
};

export default GuideMyDApp;
