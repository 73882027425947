import ViewAllHeader from 'core/layout/components/ViewAllHeader';
import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { DAppItem } from 'core/components';
import { DAppItemProps } from 'core/components';
import useDapps from 'hooks/useDapps';
import { IDapp } from 'interfaces/dapp';
import useBookmarks from 'hooks/queries/useBookmarks';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  selectUser,
  selectDappsByCategory,
  selectDappCategoryNames,
} from 'store/selector';
import useLocale from 'hooks/useLocale';
import { useTranslation } from 'react-i18next';
import useRotationFeatured from 'hooks/useRotationFeatured';

function ViewAllContainer() {
  const { t } = useTranslation('search');

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const category = params.get('category') ?? '';
  console.debug(`path: ${category}`);
  const navigate = useNavigate();

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [_, path] = useLocale();

  const dappsByCategory = useSelector(selectDappsByCategory);
  const categoryNames = useSelector(selectDappCategoryNames);

  const user = useSelector(selectUser);
  const { data: bookmarksData } = useBookmarks(user?.userId);
  const { populars, featured } = useDapps();

  // (counter : 0, 1, 2 ... counter[1] delay time)
  const [counter] = useRotationFeatured({ featured });
  // fade featured counter
  const currentItem = featured && featured[counter];

  const [allCategories, setAllCategories] = useState<string[]>([]);
  const [items, setItems] = useState<DAppItemProps[]>([]);

  const dappsToDappItemProps = useCallback(
    (dapps: IDapp[]): DAppItemProps[] =>
      dapps.map(dapp => {
        return {
          bookmark:
            bookmarksData && bookmarksData.bookmarks.includes(dapp.dappId),
          dapp: dapp,
        };
      }),
    [bookmarksData]
  );

  useEffect(() => {
    categoryNames && setAllCategories(['Popular'].concat(categoryNames));
  }, [categoryNames]);

  useEffect(() => {
    //  if a given category does not exist, redirect to 'all'.
    if (allCategories.length > 0 && !allCategories.includes(category)) {
      return navigate(`${path}/all`, { replace: true });
    }
  }, [category, allCategories]);

  useEffect(() => {
    // popular
    if (category === 'Popular') {
      setItems(populars ?? []);
    } else {
      // rest
      setItems(
        dappsToDappItemProps(
          dappsByCategory[category.length === 0 ? 'All' : category] ?? []
        )
      );
    }
  }, [category, dappsByCategory, bookmarksData, user, populars]);

  return (
    <Fragment>
      <ViewAllHeader name={category} />

      <main className="layout-contents-container page-view_all">
        {items && (
          <ul className="list-dapps" data-type="round">
            {/*// TODO: replace with rotating component*/}
            {featured && featured.length > 0 && (
              <li className="rotation_wrap">
                {featured?.map((_item: DAppItemProps, _index: number) => {
                  return (
                    currentItem && (
                      <div
                        key={_index}
                        className={`rotation_item ${
                          counter === _index
                            ? 'featured_fade_in'
                            : 'featured_fade_out'
                        }`}
                      >
                        <DAppItem
                          type="list"
                          {...currentItem}
                          featured={`${t('featuredText')}`}
                        />
                      </div>
                    )
                  );
                })}
              </li>
            )}
            {items.map((_item: DAppItemProps, _index: number) => {
              return (
                <li key={_index}>
                  <DAppItem type="list" {..._item} />
                </li>
              );
            })}
          </ul>
        )}
      </main>
    </Fragment>
  );
}

export default ViewAllContainer;
