import { useEffect, useState } from 'react';
import { Language } from 'types/language';
import { getAllLanguages } from 'assets/locales/i18next';
import { useSelector } from 'react-redux';
import { selectLocale } from 'store/selector';

const languages = getAllLanguages();

const getPath = (locale: string) => {
  const lowerCase = locale.toLowerCase();
  if (languages.includes(lowerCase)) {
    if (lowerCase !== 'ko') {
      return `/${lowerCase}`;
    } else {
      return '';
    }
  } else return '';
};

const useLocale = (): [Language, string] => {
  const locale = useSelector(selectLocale);
  const [lang, setLang] = useState<Language>(locale);
  const [urlPath, setUrlPath] = useState<string>(getPath(locale));

  useEffect(() => {
    if (languages.includes(locale)) {
      setLang(locale);
    }
    setUrlPath(getPath(locale));
  }, [locale]);

  return [lang, urlPath];
};

export default useLocale;
