import React from 'react';

import { DefaultLayout } from 'core/layout';
import { HomePage, SearchPage, DetailsPage, ViewAllPage } from 'pages';
import { withLocalization } from 'hoc/withLocalization';

export type PageRouteProps = {
  title: string;
  path: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  layout: React.ComponentType<any>;
};

export const routes: Array<PageRouteProps> = [
  {
    title: 'DAPP',
    path: '',
    layout: DefaultLayout,
    component: withLocalization(HomePage),
  },
  {
    title: 'Search',
    path: 'search',
    layout: DefaultLayout,
    component: withLocalization(SearchPage),
  },
  {
    title: '',
    path: 'details/:dappId',
    layout: DefaultLayout,
    component: withLocalization(DetailsPage),
  },
  {
    title: '',
    path: 'all',
    layout: DefaultLayout,
    component: withLocalization(ViewAllPage),
  },
];
