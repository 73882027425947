export class ChainType {
  static readonly Ethereum = new ChainType(
    'Ethereum',
    1027,
    1,
    '60',
    'ETH',
    18
  );

  static readonly Smartchain = new ChainType(
    'Smartchain',
    87411,
    56,
    '9006',
    'BNB',
    18
  );
  static readonly Solana = new ChainType('Solana', 5426, null, '501', 'SOL', 9);
  static readonly Polygon = new ChainType(
    'Matic',
    3890,
    137,
    '966',
    'MATIC',
    18
  );
  static readonly Klaytn = new ChainType(
    'Klaytn',
    4256,
    1001,
    '8217',
    'KLAY',
    18
  );

  static readonly chainTypes: ChainType[] = [
    this.Ethereum,
    this.Polygon,
    this.Klaytn,
    this.Solana,
    this.Smartchain,
  ];

  private constructor(
    private name: string,
    private coinId: number,
    private chainId: number | null,
    private slip44: string,
    private symbol: string,
    private decimals: number
  ) {}

  static getNetworkNameByCoinId(coinId: number | null): string {
    if (!coinId) return '';
    const filtered = this.chainTypes.filter(each => each.coinId === coinId);
    return filtered.length > 0 ? filtered[0].name : '';
  }

  static getAllNetworksNames(): string[] {
    return this.chainTypes.map(each => each.name);
  }

  static getCoinIdByNetworkName(name: string): string {
    const filtered = this.chainTypes.filter(
      each => each.name.toLowerCase() === name.toLowerCase()
    );
    return filtered.length > 0 ? filtered[0].coinId.toString() : '';
  }
}
