import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLocalState } from 'utils';

export interface IBaseCurrencyState {
  baseCurrency: string;
}

const initialState: IBaseCurrencyState = {
  baseCurrency: 'USD',
};

export const baseCurrencySlice = createSlice({
  name: 'baseCurrencyState',
  initialState: () => {
    return getLocalState('baseCurrencyState', initialState);
  },
  reducers: {
    setBaseCurrency: (
      state: IBaseCurrencyState,
      action: PayloadAction<string | null>
    ) => {
      if (action.payload) state.baseCurrency = action.payload;
      else state.baseCurrency = initialState.baseCurrency;
    },
  },
});

export const { setBaseCurrency } = baseCurrencySlice.actions;

export default baseCurrencySlice.reducer;
