import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const { t } = useTranslation('notFound');
  const navigate = useNavigate();

  return (
    <main role="main" className="page-error">
      <div className="inner">
        <div className="error-404">
          <h1 className="title">{t('title')}</h1>
          <h2 className="sub-title">{t('subTitle')}</h2>
          <p className="desc">{t('desc')}</p>
          <button
            className="btn-back"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('back')}
          </button>
        </div>
      </div>
    </main>
  );
}

export default NotFound;
