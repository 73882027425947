import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type ViewAllHeaderProps = {
  name: string;
};

function ViewAllHeader({ name }: ViewAllHeaderProps) {
  const navigate = useNavigate();
  const { t } = useTranslation('home');

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <header className="layout-navigation-header default view_all_header">
      <div className="left-actions">
        <button
          type="button"
          className="icon-buttons back"
          onClick={onClickBack}
        >
          back
        </button>
      </div>
      <div className="title_wrap">
        <h2 className="title">{t(`${name.toLowerCase()}`)}</h2>
      </div>
    </header>
  );
}

export default ViewAllHeader;
