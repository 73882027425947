import React from "react";

export type DefaultRouteProps = {
  title?: string;
  path?: string;
  component: React.ComponentType<any>; // eslint-disable-line
  layout: React.ComponentType<any>; // eslint-disable-line
};

function DefaultRoute(props: DefaultRouteProps) {
  const { component: Component, layout: Layout, ...others } = props;
  return (
    <Layout>
      <Component {...others} />
    </Layout>
  );
}

export default DefaultRoute;
