import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { DefaultRoute } from 'core/routes/';
import { PageRouteProps, routes } from 'core/routes/routes';
import { withDappList } from './hoc/withDappList';
import { withAuth } from './hoc/withAuth';
import NotFound from './NotFound';

import 'assets/styles/burrito.dapp.ui.scss';

import 'assets/locales';
import { withLocalization } from './hoc/withLocalization';
import { config } from './config';

const LocalizedNotFound = withLocalization(NotFound);

function HistoryListener() {
  const location = useLocation();
  // const burritoEventMutation = useUpdateBurritoEvent();
  // const user = useSelector(selectUser);

  useEffect(() => {
    config.ENV_NAME == 'development' && console.log(location);
    // todo: 'dapp-pv' counting 기준 정해지면 코드 처리 예정
    // burritoEventMutation.mutate({
    //   eventType: BurritoEvent.DAPP_PV,
    //   userId: user?.userId.toString() ?? null,
    //   metadata: JSON.stringify({
    //     url: location.pathname + location.search,
    //   }),
    // });
  }, [location]);

  return null;
}

function App() {
  return (
    <BrowserRouter>
      <HistoryListener />
      <Routes>
        <Route path="/">
          {routes.map((_item: PageRouteProps, _index: number) => {
            const { layout, component, ...others } = _item;
            return (
              <Route
                key={_index}
                path={_item.path}
                element={
                  <DefaultRoute
                    layout={layout}
                    component={component}
                    {...others}
                  />
                }
              />
            );
          })}
        </Route>
        <Route path="*" element={<Navigate to="/404" replace />} />
        <Route path="/404" element={<NotFound />} />

        <Route path="/:locale">
          {routes.map((_item: PageRouteProps, _index: number) => {
            const { layout, component, ...others } = _item;
            return (
              <Route
                key={_index}
                path={_item.path}
                element={
                  <DefaultRoute
                    layout={layout}
                    component={component}
                    {...others}
                  />
                }
              />
            );
          })}
          <Route path="404" element={<LocalizedNotFound />} />
          <Route path="*" element={<Navigate to="404" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default withDappList(withAuth(App));
