import React, { Fragment } from 'react';
import addMyDapp from 'assets/images/add_my_dapp_icon.svg';
import { MyDAppItem } from '../dapp-item/mydapp';
import GuideMyDApp from '../categoriesDApp/categoryDAppList/GuideMyDApp';
import { DAppItemProps } from '../dapp-item';
import { useTranslation } from 'react-i18next';
import CategoryTitle from '../categoryTitle';
import myDappIcon from 'assets/images/mydapp_icon_img.svg';
import useRotationFeatured from 'hooks/useRotationFeatured';

interface MyDAppProps {
  featured: DAppItemProps[] | null;
  bookmarked: DAppItemProps[] | null;
}

const MyDApp = ({ featured, bookmarked }: MyDAppProps) => {
  const { t } = useTranslation('home');

  // (counter[0] : 0, 1, 2 ... counter[1] delay time)
  const [counter] = useRotationFeatured({ featured });
  // fade featured counter
  const currentItem = featured && featured[counter];

  return (
    <section className="category_dapp_container inner">
      <CategoryTitle
        category={'Favorite'}
        icon={myDappIcon}
        title={`${t('favorite')}`}
      />
      <article className="category_dapp_list inner">
        <Fragment>
          {bookmarked && bookmarked?.length <= 0 && (
            <GuideMyDApp>{`${t('mydappGuide')}`}</GuideMyDApp>
          )}
          <ul className="my_dapp_container">
            {bookmarked && bookmarked?.length <= 0 && (
              <li className="add_my_dapp_button">
                <button>
                  <img src={addMyDapp} alt="add my dapp icon" />
                </button>
              </li>
            )}
            {featured && featured.length > 0 && (
              <li className="rotation_wrap">
                {featured?.map((_item: DAppItemProps, _index: number) => {
                  return (
                    currentItem && (
                      <div
                        key={_index}
                        className={`rotation_item ${
                          counter === _index
                            ? 'featured_fade_in'
                            : 'featured_fade_out'
                        }`}
                      >
                        <MyDAppItem
                          type="main"
                          {...currentItem}
                          featured={`${t('featured')}`}
                        />
                      </div>
                    )
                  );
                })}
              </li>
            )}
            {bookmarked?.map((item: DAppItemProps, _index: number) => {
              return (
                <li key={_index}>
                  <MyDAppItem type="main" {...item} />
                </li>
              );
            })}
          </ul>
        </Fragment>
      </article>
    </section>
  );
};

export default MyDApp;
