import React, { forwardRef, useEffect, useState } from 'react';

import { SearchFieldProps } from './types';

// eslint-disable-next-line
function BDUISearchField(props: SearchFieldProps, ref: any) {
  const {
    disabled = false,
    clearable = true,
    className = '',
    onClear,
    onChange,
    onSubmit,
    style,
    ...otherProps
  } = props;
  const [_value, _setValue] = useState<string>('');
  const [_focus, _setFocus] = useState<boolean>(false);

  useEffect(() => {
    if (props.value !== undefined) {
      _setValue(props.value as string);
    }
  }, [props.value]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value: string = event.currentTarget.value as string;
    _setValue(value);
    if (onChange) {
      onChange(event);
    }
  };

  const handleFocus = () => {
    _setFocus(true);
  };

  const handleBlur = () => {
    _setFocus(false);
  };

  const handleClickClear = () => {
    _setValue('');
    if (onClear) {
      onClear();
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // _setValue('');
    onSubmit && onSubmit(event);
    // onClear && onClear();
  };

  return (
    <div
      data-disabled={disabled}
      data-focus={_focus.toString()}
      className={`searchfield ${className}`}
      style={style}
    >
      <form onSubmit={handleSubmit}>
        <input
          type="search"
          disabled={disabled}
          value={_value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...otherProps}
          ref={ref}
        />
      </form>

      <div className="actions">
        {clearable && _value.length > 0 && (
          <button
            type="button"
            className="btn-action clear"
            onClick={handleClickClear}
          >
            Clear
          </button>
        )}
      </div>
    </div>
  );
}

export const SearchField = forwardRef(BDUISearchField);
