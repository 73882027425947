import React, { useCallback, useEffect, useState } from 'react';
import { DAppBannerItemProps } from '../banner/types';
import { DAppItem } from '../dapp-item';
import { useSelector } from 'react-redux';
import { DAppItemProps } from '../dapp-item';
import { selectUser, selectDapps } from 'store/selector';
import useBookmarks from 'hooks/queries/useBookmarks';
import { mapDappIdToDapps } from 'utils';
import { Link } from 'react-router-dom';
import useLocale from 'hooks/useLocale';

interface SubBannerProps {
  banner: DAppBannerItemProps[];
}

function SubBanner({ banner }: SubBannerProps) {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [_, path] = useLocale();

  const user = useSelector(selectUser);
  const { data: bookmarksData } = useBookmarks(user?.userId);

  const [dappItem, setDappItem] = useState<DAppItemProps>();

  const dapps = useSelector(selectDapps);

  const initSubBannerDappItem = useCallback(
    (dappId: number) => {
      if (!dapps || !banner) return;
      if (dapps) {
        const [dapp] = mapDappIdToDapps(dapps, dappId ? [Number(dappId)] : []);

        if (!dapp) return;

        setDappItem({
          bookmark:
            bookmarksData && bookmarksData.bookmarks.includes(dapp.dappId),
          dapp: dapp,
        });
      }
    },
    [dapps, banner, bookmarksData, user]
  );

  useEffect(() => {
    if (banner) {
      banner.forEach(item => {
        item.metadata?.dappId &&
          initSubBannerDappItem(Number(item.metadata.dappId));
      });
    }
  }, [dapps, bookmarksData, user]);

  return (
    <>
      {banner &&
        banner.map((item, index) => {
          return (
            <section className="sub_banner_wrap" key={index}>
              <div className="sub_banner_container">
                <Link
                  to={`${path}/details/${item.metadata?.dappId}`}
                  rel="noreferrer"
                  // onClick={handleLinkClick}
                >
                  <img
                    className="banner-image"
                    src={item.imageUri}
                    alt="banner"
                  />
                </Link>
                {dappItem &&
                  dappItem.dapp.dappId &&
                  String(dappItem.dapp.dappId) === item.metadata?.dappId && (
                    <div className="dapp_banner_item">
                      <DAppItem type="banner" {...dappItem} />
                    </div>
                  )}
              </div>
            </section>
          );
        })}
    </>
  );
}

export default SubBanner;
