import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { IUser, IUserProfileResponse } from 'interfaces/user';
import { axiosInstance } from './index';
import { config } from '../../config';

const getUser = async (): Promise<IUser> => {
  const { data } = await axiosInstance.get<IUserProfileResponse>(`/my/profile`);
  if (config.ENV_NAME === 'development') {
    console.log(`profile: ${JSON.stringify(data)}`);
  }
  return { userId: data?.myInfo?.userId };
};

const useUserProfile = () => {
  return useQuery<IUser, AxiosError>('/my/profile', getUser, { retry: false });
};

export default useUserProfile;
