import { axiosInstance } from './index';
import { useMutation } from 'react-query';

export interface IRecentSearchParams {
  action: 'set' | 'unset' | 'reset';
  word?: string;
  dappId?: bigint | string;
}

const updateRecentSearch = async (params: IRecentSearchParams) => {
  const { data } = await axiosInstance.post<{ success: boolean }>(
    `/dapps/search`,
    params
  );
  return data;
};

const useUpdateRecentSearch = () => {
  return useMutation(
    (params: IRecentSearchParams) => updateRecentSearch(params),
    {
      retry: false,
    }
  );
};

export default useUpdateRecentSearch;
