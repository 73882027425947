import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type DefaultLayoutProps = {
  children: React.ReactNode;
};

function DefaultLayout(props: DefaultLayoutProps) {
  const location = useLocation();
  const [gnbHeight, setGnbHeight] = useState<number>(50);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const gnb = document.querySelector('.layout-navigation-header');
    setGnbHeight(gnb ? gnb?.clientHeight : 50);
  }, []);

  return (
    <div
      className="layout-wrap"
      style={{ ['--gnbHeight' as string]: `${gnbHeight}px` }}
    >
      {props.children}
    </div>
  );
}

export default DefaultLayout;
