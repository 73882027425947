import popularIcon from 'assets/images/popular_icon_img.svg';
import React, { Fragment } from 'react';
import { DAppItem, DAppItemProps } from '../dapp-item';
import { useTranslation } from 'react-i18next';
import CategoryTitle from '../categoryTitle';

type PopularDAppProps = {
  dapps: DAppItemProps[];
};

// TODO: duplicate - 'CategorysDapp'

const PopularDApp = ({ dapps }: PopularDAppProps) => {
  const { t } = useTranslation('home');
  return (
    <section className="category_dapp_container inner">
      <CategoryTitle
        category={'Popular'}
        icon={popularIcon}
        title={`${t('popular')}`}
      />

      {dapps && (
        <article className="category_dapp_list popular_dapp_list">
          <Fragment>
            {Array.from({ length: 15 / 3 }, (_, i) => i * 3).map(
              (item, index) => (
                <ol key={`ol-${index}`} className="list_item">
                  {dapps &&
                    dapps
                      .map((item: DAppItemProps, _index: number) => {
                        return (
                          <li key={_index}>
                            {/* _index = 1,2,3 ... index = 0, 1, 2, 3 ... index * 2 = 0, 2, 4, 6, 8 */}
                            <DAppItem type="main" {...item} rank={_index + 1} />
                          </li>
                        );
                      })
                      .slice(item, item + 3)}
                </ol>
              )
            )}
          </Fragment>
        </article>
      )}
    </section>
  );
};

export default PopularDApp;
