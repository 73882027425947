import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { axiosInstance } from './index';

const getBookmarks = async (): Promise<{ bookmarks: number[] }> => {
  const { data } = await axiosInstance.get(`/dapps/bookmarks`);
  return data;
};

const useBookmarks = (userId: bigint | undefined) => {
  return useQuery<{ bookmarks: number[] }, AxiosError>(
    '/dapps/bookmarks',
    getBookmarks,
    {
      retry: false,
      enabled: !!userId,
    }
  );
};

export default useBookmarks;
