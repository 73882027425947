import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import i18next, { getAllLanguages } from 'assets/locales/i18next';
import { getBaseCurrency } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { setBaseCurrency } from 'store/baseCurrency/baseCurrencySlice';
import { setLocale } from 'store/locale/localeSlice';
import { selectLocale } from 'store/selector';
import { Language } from 'types/language';

export function withLocalization(WrappedComponent: React.ComponentType) {
  const ComponentWithLocalization = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { locale: localeParam } = useParams<{ locale: string }>();
    const locale = useSelector(selectLocale);

    const baseCurrency = getBaseCurrency();
    const dispatch = useDispatch();

    const languages = getAllLanguages();

    useEffect(() => {
      if (typeof localeParam === 'undefined') {
        i18next.changeLanguage('ko');
        dispatch(setLocale('ko'));
      } else {
        const lowerCased = localeParam.toLowerCase();
        if (languages.includes(lowerCased)) {
          i18next.changeLanguage(lowerCased);
          dispatch(setLocale(lowerCased as Language));
          if (lowerCased === 'ko') {
            const replaced = location.pathname.replace(`/${lowerCased}`, '');
            replaced.length !== 0
              ? navigate(replaced, { replace: true })
              : navigate('/');
          }
        } else {
          navigate(`/${locale}/404`, { replace: true });
        }
      }
    }, [localeParam]);

    useEffect(() => {
      if (typeof baseCurrency !== 'undefined') {
        dispatch(setBaseCurrency(baseCurrency));
      }
    }, [baseCurrency]);

    return <WrappedComponent />;
  };

  return ComponentWithLocalization;
}
