import { useEffect, useState } from 'react';
import { DAppItemProps } from '../core/components';

interface useRotationFeaturedProps {
  featured: DAppItemProps[] | null;
}

const useRotationFeatured = ({ featured }: useRotationFeaturedProps) => {
  const [counter, setCounter] = useState(0);
  const delay = 7000;

  useEffect(() => {
    const interval = setInterval(() => {
      if (!featured || featured.length === 0) return;
      if (featured) {
        const featuredLength = featured.length;
        return setCounter((counter + 1) % featuredLength);
      }
    }, delay);
    return () => {
      clearInterval(interval);
    };
  }, [counter, featured?.length]);
  return [counter] as const;
};

export default useRotationFeatured;
