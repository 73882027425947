import React, { Fragment } from 'react';
import { CategoriesDApp } from '../../core/components';
import viewall_arrow_icon from 'assets/images/viewall_arrow_icon.svg';
import SubBanner from 'core/components/subbanner';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useLocale from 'hooks/useLocale';
import useDapps from '../../hooks/useDapps';
import MyDApp from '../../core/components/myDApp';
import PopularDApp from '../../core/components/popularDApp';
import useDappBanner from 'hooks/queries/useDappBanners';

type ListByCategoryProps = {
  categories: string[];
};

function NewListByCategory({ categories }: ListByCategoryProps) {
  const { t } = useTranslation('home');
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [lang, path] = useLocale();
  const { bookmarked, featured, populars } = useDapps();

  const { data: burritoDappSubBannerData1 } = useDappBanner(
    'featured1',
    lang == 'ko' ? 'KOR' : 'ENG'
  );

  const { data: burritoDappSubBannerData2 } = useDappBanner(
    'featured2',
    lang == 'ko' ? 'KOR' : 'ENG'
  );

  const { data: burritoDappSubBannerData3 } = useDappBanner(
    'featured3',
    lang == 'ko' ? 'KOR' : 'ENG'
  );

  return (
    <Fragment>
      {/*  TODO: css required */}
      <MyDApp featured={featured} bookmarked={bookmarked} />

      {populars && populars.length > 0 && <PopularDApp dapps={populars} />}

      {categories.map(category => (
        <>
          {category === 'DeFi' && burritoDappSubBannerData1 && (
            <SubBanner banner={burritoDappSubBannerData1} />
          )}
          {category === 'Collectibles' && burritoDappSubBannerData2 && (
            <SubBanner banner={burritoDappSubBannerData2} />
          )}
          {category === 'Social' && burritoDappSubBannerData3 && (
            <SubBanner banner={burritoDappSubBannerData3} />
          )}
          <CategoriesDApp
            key={category}
            title={`${t(`${category}`)}`}
            category={category}
          />
        </>
      ))}

      <div className="view_all_bt_wrap">
        <Link to={`${path}/all`}>
          <button className="view_all_button">
            {t('viewAllDapp')}
            <img src={viewall_arrow_icon} alt="view all icon" />
          </button>
        </Link>
      </div>
    </Fragment>
  );
}

export default React.memo(NewListByCategory);
