import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import useUpdateRecentSearch from 'hooks/queries/useUpdateRecentSearch';
import { useTranslation } from 'react-i18next';
import useLocale from 'hooks/useLocale';
import { selectDapps, selectUser } from 'store/selector';
import allDeleteIcon from 'assets/images/recentsearch_all_delete_icon.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DappPlaceholder from 'assets/images/dapp.png';
import useRecentSearch from '../../hooks/queries/useRecentSearch';
import { mapDappIdToDapps } from '../../utils';
import { IDapp } from '../../interfaces/dapp';

// 최근 검색 결과 8개로 제한
const SEARCH_RESULT_LIMIT = 8;

function RecentlySearch() {
  /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
  const [_, path] = useLocale();
  const { t } = useTranslation('search');
  const queryClient = useQueryClient();

  const user = useSelector(selectUser);
  const dapps = useSelector(selectDapps);
  // const { recentSearch } = useDapps();
  const { data: recentSearchDappIds } = useRecentSearch(user?.userId);

  const [recentSearch, setRecentSearch] = useState<IDapp[] | null>(null);

  useEffect(() => {
    getRecentSearch();
  }, [dapps, recentSearchDappIds, user]);

  const getRecentSearch = () => {
    if (!dapps || !recentSearchDappIds) return;

    const _recentSearch = mapDappIdToDapps(
      dapps,
      recentSearchDappIds?.dappIds,
      SEARCH_RESULT_LIMIT
    ).map(dapp => {
      return dapp;
    });
    setRecentSearch(_recentSearch);
  };

  const recentSearchMutation = useUpdateRecentSearch();

  const handleClickDeleteAll = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!user) return;

    recentSearchMutation.mutate(
      {
        action: 'reset',
      },
      {
        onSettled: () => queryClient.invalidateQueries('/dapps/search'),
      }
    );
  };

  return recentSearch && recentSearch?.length > 0 ? (
    <section className="section-contents recently-search">
      {user && (
        <div className="title_wrap">
          <h2 className="title">{t('recentSearch')}</h2>

          <div className="actions">
            <button
              type="button"
              className="btn-delete-all"
              onClick={e => handleClickDeleteAll(e)}
            >
              {t('deleteAll')}
              <img src={allDeleteIcon} alt="allDeleteIcon" />
            </button>
          </div>
        </div>
      )}

      <ul className="list-recent-search">
        {recentSearch?.map((_item, _index) => {
          return (
            <li key={`keyword-${_index}`}>
              <Link
                to={`${path}/details/${_item.dappId}`}
                className="item-recent-search"
              >
                <LazyLoadImage
                  src={_item.dappIconUrl || DappPlaceholder}
                  alt={`${_item.dappName} logo`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = DappPlaceholder;
                  }}
                />
                <div className="text_wrap">
                  <span className="keyword">{_item.dappName}</span>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </section>
  ) : (
    <></>
  );
}

export default React.memo(RecentlySearch);
