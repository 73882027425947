import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setDappListState } from 'store/dappList/dappListSlice';
import { IDappListJson, IDappVersion } from 'interfaces/dapp';
import { config } from 'config';
import { RootState } from 'store';

export function withDappList(WrappedComponent: React.ComponentType) {
  const DAPP_VERSION_OBJECT_NAME = 'dappVersion.json';
  const DAPP_LIST_OBJECT_NAME = `dappList_${
    window.webkit ? 'ios' : 'aos'
  }.json`;

  const ComponentWithDappList = () => {
    const dappListState = useSelector(
      (state: RootState) => state.dappListState
    );
    const dispatch = useDispatch();

    const getIfNotLatest = async () => {
      try {
        // fetch the latest version from the s3 bucket.
        const versionRes = await axios.get<IDappVersion | null>(
          `${config.S3_ENDPOINT}/${DAPP_VERSION_OBJECT_NAME}`
        );

        // todo: navigate to error page.
        if (!versionRes.data) return;
        const { version: latestVer } = versionRes.data;

        // check if 'dappList' is saved in the localStorage
        if (dappListState) {
          // successfully fetched the current version
          // check if the version matches
          if (latestVer == dappListState.version) {
            return;
          } else {
            // fetch the latest dappList from s3 bucket
            const dappListRes = await axios.get<IDappListJson | null>(
              `${config.S3_ENDPOINT}/${DAPP_LIST_OBJECT_NAME}`
            );
            // todo: navigate to error page.
            if (!dappListRes.data) return;
            const { data: latestDapps } = dappListRes;

            const dappList = latestDapps.dapps.map(each => {
              return {
                ...each,
                dappCatIds: each.dappCatIds.map(String),
                dappMainnetCoinIds: each.dappMainnetCoinIds.map(String),
              };
            });

            // update redux store
            dispatch(
              setDappListState({
                dapps: dappList,
                dappCategories: latestDapps?.dappCategories,
                version: latestVer,
              })
            );
          }
        } else {
          // if dappList not in the localStorage
          // fetch the latest dappList from s3 bucket
          const dappListRes = await axios.get<IDappListJson | null>(
            `${config.S3_ENDPOINT}/${DAPP_LIST_OBJECT_NAME}`
          );
          // todo: navigate to error page.
          if (!dappListRes.data) return;
          const { data: latestDapps } = dappListRes;
          const dappList = latestDapps.dapps.map(each => {
            return {
              ...each,
              dappCatIds: each.dappCatIds.map(String),
              dappMainnetCoinIds: each.dappMainnetCoinIds.map(String),
            };
          });

          // update redux store
          dispatch(
            setDappListState({
              dapps: dappList,
              dappCategories: latestDapps?.dappCategories,
              version: latestVer,
            })
          );
        }
      } catch (e) {
        console.error(e);
      }
    };

    useEffect(() => {
      getIfNotLatest();
    }, []);

    return <WrappedComponent />;
  };

  return ComponentWithDappList;
}
