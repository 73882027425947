import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { DAppBannerItemProps } from './types';
import BannerItem from './banner-item/BannerItem';
import { useSelector } from 'react-redux';
import { selectDapps, selectUser } from '../../../store/selector';
import useBookmarks from '../../../hooks/queries/useBookmarks';
import { mapDappIdToDapps } from '../../../utils';

SwiperCore.use([Autoplay, Navigation, Pagination]);

interface BannersSliderProps {
  banners: DAppBannerItemProps[];
}

export function BannerSlider(props: BannersSliderProps) {
  const { banners } = props;
  const user = useSelector(selectUser);
  const { data: bookmarksData } = useBookmarks(user?.userId);
  const dapps = useSelector(selectDapps);

  return (
    <Swiper
      slidesPerView={1.1}
      spaceBetween={10}
      autoplay={{ delay: 3000 }}
      loop={true}
      centeredSlides={true}
      // pagination={{
      //   clickable: true,
      //   type: 'fraction',
      // }}
      modules={[Pagination]}
      className="swiper-banner dapp_banner"
    >
      {banners.map((item, index) => {
        if (!dapps) return;

        const dappId = item.metadata?.dappId;

        const [dapp] = mapDappIdToDapps(dapps, dappId ? [Number(dappId)] : []);

        const dappItemProp = dapp
          ? {
              bookmark:
                bookmarksData && bookmarksData.bookmarks.includes(dapp.dappId),
              dapp: dapp,
            }
          : undefined;

        return (
          <SwiperSlide key={index} className="banner-item">
            <BannerItem banner={item} dappItemProp={dappItemProp} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
