import React, { useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import useDapp from 'hooks/useDapp';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DappPlaceholder from 'assets/images/dapp.png';
import { DAppItemProps } from '../../components/dapp-item/details/types';

type DetailsHeaderProps = {
  // name?: string;
  // bookmark?: boolean;
  back?: boolean;
  bg?: boolean;
  invertable?: boolean;
  leftActions?: React.ReactNode;
  onClickBack?: () => void;
  goDappHandler?: () => void;
  setIsMoveHandler: React.Dispatch<React.SetStateAction<boolean>>;
  dappItem: DAppItemProps;
};

gsap.registerPlugin(ScrollTrigger);
function DetailsHeader(props: DetailsHeaderProps) {
  const {
    // name,
    // bookmark = true,
    back = false,
    invertable = true,
    leftActions,
    bg = true,
    onClickBack,
    setIsMoveHandler,
    dappItem,
  } = props;
  const headerRef = useRef(null);
  const [invert, setInvert] = useState<boolean>(false);

  const { t } = useTranslation('details');
  const [item] = useDapp();
  // const [isMove, setIsMove] = useState<boolean>(false);

  useLayoutEffect(() => {
    const container = document.querySelector('.layout-wrap');
    const header = document.querySelector('.layout-navigation-header');

    if (headerRef.current !== undefined) {
      ScrollTrigger.create({
        trigger: container,
        markers: false,
        start: `top -${header?.clientHeight}px`,
        onEnter: () => {
          setInvert(true);
        },
        onLeaveBack: () => {
          setInvert(false);
        },
      });
    }
  }, [headerRef]);

  return (
    <header
      className={`layout-navigation-header details ${bg ? '' : 'wt'}`}
      data-invertable={invertable}
      data-invert={invertable && invert}
      ref={headerRef}
    >
      <h2 className="hidden">DAPP Navigation</h2>
      <div className="left-actions details-left-actions">
        {back && (
          <button
            type="button"
            className="icon-buttons back"
            onClick={onClickBack}
          >
            back
          </button>
        )}
        {leftActions}
      </div>
      <div className="navigation-title">
        {/* design_v1.5 아이콘으로 변경 */}
        <div className="title-icon">
          <LazyLoadImage
            src={dappItem.dapp?.dappIconUrl || DappPlaceholder}
            alt={`${dappItem.dapp?.dappName} logo`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = DappPlaceholder;
            }}
          />
        </div>
        {/* <strong>{name}</strong> */}
      </div>
      {/* <button className="icon-buttons bookmark">{bookmark}</button> */}
      {/* design_v1.5 이동하기 버튼 추가 */}
      {/*이동 버튼 */}
      {item && (
        <div className="navigition-button">
          {item.dapp.dappUrl && (
            <button
              className="btn-common purple"
              onClick={() => setIsMoveHandler(true)}
            >
              {t('button.goDapp')}
            </button>
          )}
        </div>
      )}
    </header>
  );
}

export default DetailsHeader;
