import React, { Fragment } from 'react';
import { DAppBannerItemProps } from '../types';
import { DAppItemProps } from '../../dapp-item';
import { DAppItem } from '../../dapp-item';
import useLocale from 'hooks/useLocale';
import { Link } from 'react-router-dom';

interface BannerItemProps {
  banner: DAppBannerItemProps;
  dappItemProp?: DAppItemProps;
}

function BannerItem({ banner, dappItemProp }: BannerItemProps) {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [_, path] = useLocale();

  return (
    <>
      {banner && (
        <Fragment>
          <Link
            to={`${path}/details/${banner.metadata?.dappId}`}
            rel="noreferrer"
            // onClick={handleLinkClick}
          >
            <img className="banner-image" src={banner.imageUri} alt="banner" />
          </Link>
          {dappItemProp &&
            dappItemProp.dapp.dappId &&
            String(dappItemProp.dapp.dappId) === banner.metadata?.dappId && (
              <div className="dapp_banner_item">
                <DAppItem type="banner" {...dappItemProp} />
              </div>
            )}
        </Fragment>
      )}
    </>
  );
}

export default BannerItem;
