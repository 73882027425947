import { axiosInstance } from './index';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { config } from 'config';

export type AssetPriceItem = {
  price: BigInt;
  percentChange24h: number;
  priceChange24h: number;
  txVolume24h: number;
  marketCap: number;
  lastUpdated: string;
};

export type AssetPrice = {
  id: number;
  symbol: string;
  timestamp: string;
  maxSupply: number;
  circulatingSupply: number;
  quote: {
    [key: string]: AssetPriceItem;
  };
};

interface IGetTokenPriceResponse {
  error: {
    code: number;
    msg: string;
  };
  data: AssetPrice[];
}

// fiats: comma separated base currencies.
// coinIds: comma separated coinIds.
const getTokenPrice = async (fiats: string, coinIds: string | null) => {
  const { data } = await axiosInstance.get<IGetTokenPriceResponse>(
    '/api/v1/asset-info',
    {
      params: {
        fiats: fiats,
        tokens: coinIds,
      },
      baseURL: config.ASSET_BASE_URL,
    }
  );

  return data.data;
};

export const useTokenPrice = (fiats = 'USD', coinIds: string | null) => {
  return useQuery<AssetPrice[], AxiosError>(
    ['/api/v1/asset-info', fiats, coinIds],
    () => getTokenPrice(fiats, coinIds),
    {
      retry: false,
      enabled: coinIds != null,
    }
  );
};
