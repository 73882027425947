import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import dappListState from 'store/dappList/dappListSlice';
import userState from 'store/user/userSlice';
import baseCurrencyState from 'store/baseCurrency/baseCurrencySlice';
import localeState from 'store/locale/localeSlice';
import { setLocalState } from 'utils';
import { config } from 'config';

const logger = createLogger({
  diff: true,
  duration: true,
  timestamp: true,
});

const middlewares = [...(config.ENV_NAME === 'development' ? [logger] : [])];

const rootReducer = combineReducers({
  dappListState,
  userState,
  baseCurrencyState,
  localeState,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
});

// save redux state to localStorage at every update.
store.subscribe(() => {
  setLocalState('dappListState', store.getState().dappListState);
  setLocalState('me', store.getState().userState);
  setLocalState('baseCurrencyState', store.getState().baseCurrencyState);
  setLocalState('localeState', store.getState().localeState);
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const exported = {
  ...store,
};

export default exported;
