import { useQuery } from 'react-query';
import { axiosInstance } from './index';
import { AxiosError } from 'axios';

interface BurritoResponse<T> {
  err: {
    code: number;
    msg: string;
  };
  data: T;
}

interface DappBanners {
  name: string;
  metadata?: {
    dappId?: string;
    dappUrl?: string;
    linkUri?: string;
    dappName?: string;
    eventType?: string;
    dappMainnetNames?: string[];
  };
  imageUri: string;
  bannerOrder: number;
  durationSec: number;
  isExternalLink: boolean;
}

const getDappBanner = async (
  viewType: string,
  language: string,
  dappId: string | undefined
) => {
  const { data } = await axiosInstance.get<BurritoResponse<DappBanners[]>>(
    `/api/v1/dapps/banners/${viewType}`,
    { params: { lang: language, dappId: dappId } }
  );
  return data.data;
};

const useDappBanner = (
  viewType: string,
  language: string,
  dappId?: string | undefined
) => {
  return useQuery<DappBanners[], AxiosError>(
    `/api/v1/dapps/banners/${viewType}?dappId=${dappId}/${language}`,
    () => getDappBanner(viewType, language, dappId),
    {
      retry: false,
      // enabled: typeof dappId !== 'undefined',
    }
  );
};

export default useDappBanner;
