import { IUser } from "interfaces/user";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getLocalState } from "utils";

export interface IUserState {
  user: IUser | null;
}

const initialState: IUserState = {
  user: null,
};

export const userSlice = createSlice({
  name: "userState",
  initialState: () => {
    return getLocalState("me", initialState);
  },
  reducers: {
    setUserState: (state: IUserState, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    unSetUserState: (state) => {
      state.user = null;
    },
  },
});

export const { setUserState, unSetUserState } = userSlice.actions;

export default userSlice.reducer;
