import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const setScrollPos = (path: string) => {
  const key = `dappScrollPos:${path}`;
  sessionStorage.setItem(key, `${window.scrollY}`);
};

const restoreScrollPos = (path: string) => {
  const key = `dappScrollPos:${path}`;
  const scrollPos = sessionStorage.getItem(key);
  requestAnimationFrame(() => {
    window.scrollTo(0, scrollPos ? parseFloat(scrollPos) : 0);
  });
};

const useScrollRestoration = () => {
  const location = useLocation();

  useEffect(() => {
    restoreScrollPos(location.pathname);
  }, [location]);
};

export default useScrollRestoration;
