import { useQuery } from 'react-query';
import { axiosInstance } from './index';
import { config } from '../../config';

const getPopulars = async () => {
  const { data } = await axiosInstance.get(`/dapps/populars`);
  if (config.ENV_NAME === 'development') {
    console.log(`populars: ${JSON.stringify(data)}`);
  }
  return data;
};

const usePopular = () => {
  return useQuery<{
    populars: {
      dappIds: number[];
      cats: string[];
    };
  }>('/dapps/populars', getPopulars, { retry: false });
};

export default usePopular;
