import React, { useEffect } from 'react';
import useUserProfile from 'hooks/queries/useUserProfile';
import { useDispatch } from 'react-redux';
import { setUserState, unSetUserState } from 'store/user/userSlice';

export function withAuth(WrappedComponent: React.ComponentType) {
  const ComponentWithAuth = () => {
    const { data: user, isSuccess, isError } = useUserProfile();

    const dispatch = useDispatch();

    useEffect(() => {
      if (isSuccess) {
        if (typeof user !== 'undefined')
          dispatch(setUserState({ userId: user.userId }));
      }
      if (isError) dispatch(unSetUserState());
    }, [user, isSuccess]);

    return <WrappedComponent />;
  };

  return ComponentWithAuth;
}
