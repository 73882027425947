import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { axiosInstance } from './index';
import { config } from '../../config';

const getFeatured = async () => {
  const { data } = await axiosInstance.get(`/dapps/featured`);
  if (config.ENV_NAME === 'development') {
    console.log(`featured: ${JSON.stringify(data)}`);
  }
  return data;
};

const useFeatured = () => {
  return useQuery<{ featured: number[] }, AxiosError>(
    '/dapps/featured',
    getFeatured,
    {
      retry: false,
    }
  );
};

export default useFeatured;
