import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NavigationHeader from 'core/layout/components/NavigationHeader';
import { SearchField, DAppItem, DAppItemProps } from 'core/components';
import RecentlySearch from './RecentSearch';
import SearchResults from './SearchResults';

import useBookmarks from 'hooks/queries/useBookmarks';
import useDapps from 'hooks/useDapps';

import Fuse from 'fuse.js';
import { ChainType } from '../../constants';
import { useTranslation } from 'react-i18next';
import { hideBottomBar } from 'utils';
import {
  selectDappCategories,
  selectDappCategoryNames,
  selectDapps,
  selectUser,
} from 'store/selector';
import useUpdateBurritoEvent, {
  BurritoEvent,
} from '../../hooks/queries/useUpdateBurritoEvent';
import useRotationFeatured from 'hooks/useRotationFeatured';

function SearchContainer() {
  const { t } = useTranslation('search');

  const featuredText = t('featuredText');

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const user = useSelector(selectUser);
  const dapps = useSelector(selectDapps);
  const dappCategories = useSelector(selectDappCategories);
  const categoryNames = useSelector(selectDappCategoryNames);

  // const { featured, populars, popularCats } = useDapps();
  const { featured, populars } = useDapps();

  // (counter : 0, 1, 2 ... counter[1] delay time)
  const [counter] = useRotationFeatured({ featured });
  // fade featured counter
  const currentItem = featured && featured[counter];

  const { data: bookmarksData } = useBookmarks(user?.userId);

  const [keyword, setKeyword] = useState<string>('');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<DAppItemProps[]>([]);

  const burritoEventMutation = useUpdateBurritoEvent();

  const search = () => {
    if (!dapps || !keyword || !categoryNames) return setSearchResult([]);
    // create an index prior to search
    const idx = Fuse.createIndex(
      ['dappName', 'dappCatIds', 'dappMainnetCoinIds', 'dappUrl'],
      dapps
    );

    // search from all of all names
    const categoryNameFuse = new Fuse(categoryNames, {
      useExtendedSearch: true,
    });
    const categoryNameMatches = categoryNameFuse.search("'" + keyword);

    // convert search result to dapp_cat_id
    // and generate search string e.g `=1|=3`
    const categorySearchString = categoryNameMatches
      .map(each => {
        const category = each.item;
        const filtered = dappCategories?.filter(
          each => each.dappCatName.toLowerCase() === category.toLowerCase()
        );
        return filtered && filtered.length > 0 ? filtered[0].dappCatId : '';
      })
      ?.map(id => `=${id}`)
      .join('|');

    // same methodology applied to mainnet search
    const mainnetNameFuse = new Fuse(ChainType.getAllNetworksNames(), {
      useExtendedSearch: true,
    });
    const mainnetNameMatches = mainnetNameFuse.search("'" + keyword);
    const mainnetSearchString = mainnetNameMatches
      .map(each => {
        const name = each.item;
        return ChainType.getCoinIdByNetworkName(name);
      })
      ?.map(id => `=${id}`)
      .join('|');

    const dappNameFuse = new Fuse(
      dapps,
      {
        keys: ['dappName'],
        useExtendedSearch: true,
      },
      idx
    );

    const categoryFuse = new Fuse(
      dapps,
      {
        keys: ['dappCatIds'],
        useExtendedSearch: true,
      },
      idx
    );

    const mainnetFuse = new Fuse(
      dapps,
      {
        keys: ['dappMainnetCoinIds'],
        useExtendedSearch: true,
      },
      idx
    );

    const urlFuse = new Fuse(
      dapps,
      {
        keys: ['dappUrl'],
        useExtendedSearch: true,
      },
      idx
    );

    const categoryMatches = categoryFuse
      .search(categorySearchString)
      .map(each => each.item);
    const mainnetMatches = mainnetFuse
      .search(mainnetSearchString)
      .map(each => each.item);
    const dappNameMatches = dappNameFuse
      .search("'" + keyword)
      .map(each => each.item);
    const urlMatches = urlFuse.search("'" + keyword).map(each => each.item);

    const results = [
      ...new Set([
        ...dappNameMatches,
        ...mainnetMatches,
        ...categoryMatches,
        ...urlMatches,
      ]),
    ];

    if (results.length > 0) {
      const dappItems: DAppItemProps[] = results.map(dapp => {
        return {
          bookmark:
            user && bookmarksData
              ? bookmarksData.bookmarks.includes(dapp?.dappId)
              : undefined,
          path: dapp?.dappUrl?.replace(/["]+/g, ''),
          dapp: dapp,
          rank: populars?.length,
        };
      });
      setSearchResult(dappItems);
    } else {
      setSearchResult([]);
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSearched(true);
    setSearchKeyword(keyword);
    search();
    burritoEventMutation.mutate({
      eventType: BurritoEvent.SEARCH_DAPP,
      userId: user?.userId.toString() ?? null,
      metadata: JSON.stringify({ searchWord: keyword }),
    });
  };

  const onHandleBack = () => {
    if (isSearched) {
      setKeyword('');
      setIsSearched(false);
      return;
    }
    navigate(-1);
  };

  useEffect(() => {
    hideBottomBar();
  }, []);

  useEffect(() => {
    const _keyword = searchParams.get('keyword') as string;
    setKeyword(_keyword !== null ? _keyword : '');
  }, [searchParams]);

  return (
    <>
      <NavigationHeader back invertable={false} onClickBack={onHandleBack}>
        <SearchField
          name="search"
          placeholder={`${t('searchFieldPlaceholder')}`}
          onClear={() => {
            setKeyword('');
          }}
          onChange={event => {
            setKeyword(event.currentTarget.value);
          }}
          onSubmit={onSubmit}
          value={keyword}
        />
      </NavigationHeader>

      {!isSearched ? (
        <main className="page-search">
          <h1 className="hidden">Search</h1>

          {/* 최근 검색 */}
          <RecentlySearch />

          {/* 추천 디앱 */}
          <section className="section-contents featured_content">
            <h2 className="title">{t('recommended')}</h2>

            <ul className="list-dapps">
              {featured && featured.length > 0 && (
                <li className="rotation_wrap">
                  {featured?.map((_item: DAppItemProps, _index: number) => {
                    return (
                      currentItem && (
                        <div
                          key={_index}
                          className={`rotation_item ${
                            counter === _index
                              ? 'featured_fade_in'
                              : 'featured_fade_out'
                          }`}
                        >
                          <DAppItem
                            type="list"
                            {...currentItem}
                            featured={featuredText}
                          />
                        </div>
                      )
                    );
                  })}
                </li>
              )}
              {populars
                ?.slice(0, 10)
                ?.map((_item: DAppItemProps, _index: number) => {
                  return (
                    <li key={_index}>
                      <DAppItem type="list" {..._item} />
                    </li>
                  );
                })}
            </ul>
          </section>
        </main>
      ) : (
        <SearchResults keyword={searchKeyword} items={searchResult} />
      )}
    </>
  );
}

export default SearchContainer;
