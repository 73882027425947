/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import NavigationHeader from 'core/layout/components/NavigationHeader';
import { BannerSlider } from 'core/components/banner';
import NewListByCategory from './NewListByCategory';
import GuideSearch from './GuideSearch';

import useBurritoEvent from 'hooks/queries/useBurritoEvent';
import useScrollRestoration, { setScrollPos } from 'hooks/useScrollRestoration';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useLocale from 'hooks/useLocale';
import { showBottomBar } from 'utils';
import { selectDappCategoryNames, selectDappsByCategory } from 'store/selector';
import useDappBanner from 'hooks/queries/useDappBanners';

function HomeContainer() {
  useScrollRestoration();
  const location = useLocation();

  const { t } = useTranslation('home');
  const [lang, path] = useLocale();

  const dappsByCategory = useSelector(selectDappsByCategory);

  //design_v1.5
  const dappCategoryNames = useSelector(selectDappCategoryNames);
  const [mainCategories, setMainCategories] = useState<string[]>([]);

  // const { data: burritoEventData } = useBurritoEvent(
  //   lang == 'ko' ? 'KOR' : 'ENG'
  // );

  const { data: burritoDAppBannerData } = useDappBanner(
    'dapp_main',
    lang == 'ko' ? 'KOR' : 'ENG'
  );

  // const [burritoDAppBanner, setBurritoDappBanner] = useState<bannerItemProps[]>(
  //   []
  // );

  // useEffect(() => {
  //   if (burritoDAppBannerData) {
  //     setBurritoDappBanner([burritoDAppBannerData]);
  //   }
  // }, [burritoDAppBannerData]);

  useEffect(() => {
    showBottomBar();
  }, []);

  // design_v1.5 에서 controlItems
  useEffect(() => {
    if (dappCategoryNames) {
      setMainCategories(dappCategoryNames.filter(name => name != 'All'));
    }
  }, [dappCategoryNames]);

  return (
    <>
      <NavigationHeader
        title={`${t('title')}`}
        titlePosition={true}
        rightActions={
          <div className="header-icons">
            <Link
              to={`${path}/search`}
              className="icon-buttons search"
              onClick={() => setScrollPos(location.pathname)}
            >
              search
            </Link>
          </div>
        }
      />

      {/* {burritoEventData && burritoEventData.length > 0 && (
        <BannerSlider banners={burritoEventData}>
      )} */}

      {burritoDAppBannerData && burritoDAppBannerData.length > 0 && (
        <BannerSlider banners={burritoDAppBannerData} />
      )}

      <main className="layout-contents-container page-home">
        {mainCategories && mainCategories.length > 0 && dappsByCategory && (
          <NewListByCategory categories={mainCategories} />
        )}
      </main>

      <GuideSearch />
    </>
  );
}

export default HomeContainer;
