import React, { useLayoutEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useTranslation } from 'react-i18next';

function GuideSearch() {
  const { t } = useTranslation('home');

  const guideRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const container = document.querySelector('.layout-wrap');
    if (guideRef.current !== undefined) {
      ScrollTrigger.create({
        trigger: container,
        markers: false,
        start: `top top`,
        onEnter: () => {
          guideRef.current?.classList.add('hide');
        },
      });

      setTimeout(() => {
        if (guideRef.current !== undefined) {
          guideRef.current?.classList.add('hide');
        }
      }, 5000);
    }
  }, [guideRef]);

  return (
    <div className="guide-search" ref={guideRef}>
      <p className="message">{t('guide')}</p>
    </div>
  );
}

export default GuideSearch;
