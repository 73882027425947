import React, { useEffect, useState, useRef } from 'react';
import DetailsHeader from 'core/layout/components/DatailsHeader';

import { DAppItemDetails } from 'core/components/dapp-item/details';
import { EventSlider } from 'core/components';
import YouTube, { YouTubeProps } from 'react-youtube';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { hideBottomBar } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
// import useDappEvent from 'hooks/queries/useDappEvent';
import useLocale from 'hooks/useLocale';
import { useTranslation } from 'react-i18next';
import { setBaseCurrency } from 'store/baseCurrency/baseCurrencySlice';
import useDapp from 'hooks/useDapp';
import useUpdateBurritoEvent, {
  BurritoEvent,
} from '../../hooks/queries/useUpdateBurritoEvent';
import { selectUser } from '../../store/selector';
import useUpdateBookmark from '../../hooks/queries/useUpdateBookmark';
import { useQueryClient } from 'react-query';
import useDappBanner from 'hooks/queries/useDappBanners';

//유튜브 영상 로드
const onPlayerReady: YouTubeProps['onReady'] = event => {
  event.target.pauseVideo();
};

const opts: YouTubeProps['opts'] = {
  height: '100%',
  width: '100%',
  playerVars: {
    autoplay: 0,
    rel: 0,
    modestbranding: 1,
  },
};

function DetailsContainer() {
  const { t } = useTranslation('details');

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [searchParam] = useSearchParams();
  const currency = searchParam.get('currency');
  const [locale] = useLocale();

  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  const [item] = useDapp();
  // const { data: eventData } = useDappEvent(item?.dapp.dappId.toString());

  const bookmarkMutation = useUpdateBookmark();
  const burritoEventMutation = useUpdateBurritoEvent();

  const [isMove, setIsMove] = useState<boolean>(false);
  const [videoId, setVideoId] = useState<string>('');

  const handleGoDapp = () => {
    setIsMove(false);
    item && item.dapp.dappUrl && window.open(item.dapp.dappUrl, '_blank');

    burritoEventMutation.mutate({
      eventType: BurritoEvent.EXECUTE_DAPP,
      userId: user?.userId.toString() ?? null,
      metadata: JSON.stringify({
        dappId: item && item.dapp.dappId,
        categories: item && item.dapp.dappCatIds,
      }),
    });
  };

  useEffect(() => {
    hideBottomBar();
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = 'white';
  }, []);

  useEffect(() => {
    dispatch(setBaseCurrency(currency));
  }, [currency]);

  useEffect(() => {
    if (item?.dapp?.dappId) {
      burritoEventMutation.mutate({
        eventType: BurritoEvent.DAPP_PV,
        userId: user?.userId.toString() ?? null,
        metadata: JSON.stringify({
          dappId: item.dapp.dappId,
          url: location.pathname + location.search,
        }),
      });
    }
    if (item?.dapp.dappIntroMediaUrl) {
      const key = new URL(item.dapp.dappIntroMediaUrl).pathname;
      setVideoId(key);
    } else setVideoId('');
  }, [item?.dapp.dappIntroMediaUrl || item?.dapp.dappId]);

  useEffect(() => {
    if (item?.dapp?.dappId) {
      bookmarkMutation.mutate(
        {
          action: 'reorder',
          dappId: item.dapp.dappId.toString(),
        },
        {
          onSettled: () => queryClient.invalidateQueries('dapps/bookmarks'),
        }
      );
    }
  }, [item?.dapp.dappId]);

  const onClickBack = () => {
    navigate(-1);
  };

  //유튜브 팝업 이벤트
  const [isPlay, setIsPlay] = useState<boolean>(true);
  const playerHandler = () => {
    setIsPlay(!isPlay);
  };

  const videoContents = useRef<HTMLDivElement>(null);
  const videoLength = videoContents.current?.children.length;

  const { data: eventDappData } = useDappBanner(
    'event',
    locale == 'ko' ? 'KOR' : 'ENG',
    String(item?.dapp.dappId)
  );

  return (
    item && (
      <>
        {isMove && (
          <div className="notice-wrap" onClick={() => setIsMove(false)}>
            <div className="notice-contents">
              <div className="notice_text_wrap">
                <strong>{t('warning.title')}</strong>
                <ul>
                  <li>{t('warning.p1')}</li>
                  <li className="bold">{t('warning.p2')}</li>
                  <li>{t('warning.p3')}</li>
                </ul>
              </div>
              <div className="btn-wrap notice-btn-wrap">
                <button
                  className="btn-common light-purple"
                  onClick={() => setIsMove(!isMove)}
                >
                  {t('warning.button.dismiss')}
                </button>
                {item.dapp.dappUrl && (
                  <button className="btn-common purple" onClick={handleGoDapp}>
                    {t('warning.button.approve')}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        <DetailsHeader
          back={true}
          bg={false}
          onClickBack={onClickBack}
          goDappHandler={handleGoDapp}
          setIsMoveHandler={setIsMove}
          dappItem={item}
        />
        <main className="page-details">
          <section className="info-top">
            {/*코인 정보*/}
            <DAppItemDetails item={item} setIsMoveHandler={setIsMove} />
          </section>

          <section className="info-bottom">
            {/*이벤트 배너 슬라이드*/}

            {/* {eventData && eventData.event.dappEventBanners && (
              <div className="intro-wrap intro_event">
                <h5>{t('intro.event')}</h5>
                <EventSlider banners={eventData.event.dappEventBanners} />
              </div>
            )} */}

            {eventDappData && eventDappData.length > 0 && (
              <div className="intro-wrap intro_event">
                <h5>{t('intro.event')}</h5>
                <EventSlider banners={eventDappData} />
              </div>
            )}

            {/* {test && (
              <div className="intro-wrap intro_event">
                <h5>{t('intro.event')}</h5>
                <EventSlider banners={test} />
              </div>
            )} */}

            {videoId && (
              <div
                className={`intro-wrap ${
                  videoLength && videoLength < 2
                    ? 'intro_media'
                    : 'intro_media2'
                }`}
              >
                {/* 유튜브 영상 1개일 때 클래스 네임 intro_media 2개 이상일 시 intro_media2 css로 변경돼야함*/}
                <h5>{t('intro.media')}</h5>
                <div>
                  {/*유튜브 팝업*/}
                  {isPlay && (
                    <div className="video-wrap" onClick={playerHandler}>
                      <div className="video-contents" ref={videoContents}>
                        <YouTube
                          videoId={videoId}
                          className="main-video"
                          opts={opts}
                          onReady={onPlayerReady}
                          onEnd={e => {
                            e.target.stopVideo(0);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* 소개 */}
            {item.dapp.dappDesc &&
              item.dapp.dappDesc[locale] !== 'null' &&
              item.dapp.dappDesc[locale] !== null && (
                <div className="intro-wrap intro_title">
                  <h5>{t('intro.title')}</h5>
                  {item.dapp.dappDesc[locale]?.split('\n').map((line, idx) => (
                    <p key={`key-${idx}`} className="intro-txt">
                      {line}
                      <br />
                    </p>
                  ))}
                </div>
              )}
          </section>
        </main>
      </>
    )
  );
}

export default DetailsContainer;
