import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';
import { config } from 'config';

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  retry?: boolean;
}

let isRefreshing = false;
let requestQueue: Array<(token: string) => boolean> = [];

/* eslint-disable  @typescript-eslint/no-explicit-any */
(window as any).bat = '';

function addSubscriber(callback: (token: string) => boolean): void {
  requestQueue.push(callback);
}

(window as any).receiveToken = function (newToken: string) {
  if (config.ENV_NAME === 'development') {
    console.log(`#receiveToken - ${newToken}`);
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  (window as any).bat = newToken;

  if (config.ENV_NAME === 'development') {
    console.log(`AKHolder.gat == newToken? - ${AKHolder.gat() == newToken}`);
  }

  requestQueue = requestQueue.filter(callback => callback(newToken));

  isRefreshing = false;
};

class AKHolder {
  static token?: string = '';

  static async rt() {
    if (bwi) await bwi.rt();
  }

  static gat() {
    if (config.ENV_NAME === 'development') console.log('get access token');
    if (window.webkit) {
      if (config.ENV_NAME === 'development')
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        console.log(`bat - ${(window as any).bat}`);
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      return `Bearer ${(window as any).bat}`;
    }
    return typeof bwi !== 'undefined' ? `Bearer ${bwi.gat()}` : '';
  }
}
const axiosInstance = axios.create({
  baseURL: `${config.BASE_URL}`,
  timeout: 5000,
  headers: {
    Authorization: AKHolder.gat(),
  },
});

axiosInstance.interceptors.request.use(
  async (axiosConfig: ExtendedAxiosRequestConfig) => {
    _.set(axiosConfig, 'headers.Authorization', AKHolder.gat());
    _.set(
      axiosConfig,
      'headers.platform',
      typeof bwi !== 'undefined' ? 'android' : 'ios'
    );
    return axiosConfig;
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  async function (error: any) {
    const originalRequest = error.config;

    if (error.response.status == 401 && !originalRequest?._retry) {
      // ios refresh token
      if (window.webkit) {
        // Send a message to the iOS app to refresh the token
        if (!isRefreshing) {
          isRefreshing = true;
          window.webkit.messageHandlers.refreshToken.postMessage(null);
          if (config.ENV_NAME === 'development') {
            console.log(`#postMessage - refreshToken`);
          }
        }

        const retry = new Promise<AxiosResponse>(resolve => {
          addSubscriber((token: string) => {
            if (config.ENV_NAME === 'development') {
              console.log(`callback - ${token}`);
              console.log(
                `req - method: ${originalRequest.method}, url: ${originalRequest.url}`
              );
            }
            originalRequest.headers.Authorization = 'Bearer ' + token;
            resolve(axiosInstance(originalRequest));
            return false;
          });
        });
        return retry;
      } else {
        originalRequest._retry = true;

        // android refresh token
        await AKHolder.rt();
        if (config.ENV_NAME === 'development')
          console.log('Access Token Refreshed');

        _.set(originalRequest, 'headers.Authorization', AKHolder.gat());
        return axiosInstance(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export { axiosInstance };
