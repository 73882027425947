import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { DAppItemProps } from 'core/components/dapp-item/details';
import useBookmarks from './queries/useBookmarks';
import { mapDappIdToDapps } from 'utils';
import { useNavigate, useParams } from 'react-router-dom';
import useLocale from './useLocale';
import { selectDapps, selectUser } from 'store/selector';

const useDapp = () => {
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [_, path] = useLocale();
  const { dappId } = useParams<{ dappId: string }>();

  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const dapps = useSelector(selectDapps);

  const [dappItem, setDappItem] = useState<DAppItemProps | null>(null);

  const { data: bookmarksData } = useBookmarks(user?.userId);

  const initDappItem = () => {
    if (!dapps) return;
    if (dapps) {
      const [dapp] = mapDappIdToDapps(dapps, dappId ? [Number(dappId)] : []);
      if (!dapp) {
        return navigate(`${path}/404`, { replace: true });
      }
      setDappItem({
        bookmark:
          bookmarksData && bookmarksData.bookmarks.includes(dapp.dappId),
        dapp: dapp,
      });
    }
  };

  useEffect(() => {
    initDappItem();
  }, [dapps, bookmarksData, user]);

  return [dappItem] as const;
};

export default useDapp;
