import { useMutation } from 'react-query';
import { axiosInstance } from './index';
import { config } from '../../config';

export enum BurritoEvent {
  EXECUTE_DAPP = 'execute-dapp',
  SEARCH_DAPP = 'search-dapp',
  DAPP_PV = 'dapp-pv',
}

export interface IBurritoEventParams {
  eventType: string;
  userId: string | null;
  metadata: string | null; // should be json string or null
}

export interface IBurritoEventResponse {
  error: {
    code: number;
    msg: string;
  };
  data?: IBurritoEventParams;
  reason?: any[]; // eslint-disable-line
}

export interface IBurritoEventError {
  err: {
    code: number;
    msg: string;
  };
}

const updateBurritoEvent = async (params: IBurritoEventParams) => {
  config.ENV_NAME === 'development' && console.log(params);
  const { data } = await axiosInstance.post<IBurritoEventResponse>(
    '/logging/api/v1/event-logging',
    params,
    {
      baseURL: `${config.EVENT_BASE_URL}`,
    }
  );
  config.ENV_NAME === 'development' &&
    console.log(`data: ${JSON.stringify(data)}`);
};

const useUpdateBurritoEvent = () => {
  return useMutation(
    (params: IBurritoEventParams) => updateBurritoEvent(params),
    {
      retry: false,
    }
  );
};

export default useUpdateBurritoEvent;
