import React, { useEffect, useState, useCallback, useRef } from 'react';
import { DAppItemProps } from 'core/components';
import { selectUser } from 'store/selector';
import { IDapp } from 'interfaces/dapp';
import { useSelector } from 'react-redux';
import useBookmarks from 'hooks/queries/useBookmarks';
import { DAppItem } from 'core/components';

type CategoryDAppListProps = {
  dapps: IDapp[];
  showRank: boolean;
};

function CategoryDAppList({ dapps, showRank }: CategoryDAppListProps) {
  const user = useSelector(selectUser);

  const { data: bookmarksData } = useBookmarks(user?.userId);

  const [dappItems, setDappItems] = useState<DAppItemProps[]>([]);

  const dappsToDappItemProps = useCallback(
    (dapps: IDapp[]): DAppItemProps[] =>
      dapps.map(dapp => {
        return {
          bookmark:
            bookmarksData && bookmarksData.bookmarks.includes(dapp.dappId),
          dapp: dapp,
        };
      }),
    [bookmarksData]
  );

  useEffect(() => {
    setDappItems(dappsToDappItemProps(dapps.slice(0, 15)));
  }, [user, bookmarksData]);

  //  ref={categoryDAppListRef}
  const categoryDappListRef = useRef<HTMLElement>(null);

  function snapInitialLocation() {
    if (categoryDappListRef.current) {
      categoryDappListRef.current.scrollLeft = 0;
    }
  }

  useEffect(() => {
    if (categoryDappListRef.current) {
      window.addEventListener('load', snapInitialLocation);
    }
  }, []);

  return (
    <article className="category_dapp_list" ref={categoryDappListRef}>
      {Array.from({ length: 15 / 3 }, (_, i) => i * 3).map((item, index) => (
        <ol key={`ol-${index}`} className="list_item">
          {dappItems &&
            dappItems
              .map((item: DAppItemProps, _index: number) => {
                return (
                  <li key={_index}>
                    <DAppItem
                      type="main"
                      {...item}
                      rank={showRank ? _index + 1 : undefined}
                    />
                  </li>
                );
              })
              .slice(item, item + 3)}
        </ol>
      ))}
    </article>
  );
}

export default React.memo(CategoryDAppList);
