import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLocalState } from 'utils';
import { Language } from 'types/language';

export interface ILocaleState {
  locale: Language;
}

export const initialState: ILocaleState = {
  locale: 'ko',
};

export const localeSlice = createSlice({
  name: 'localeState',
  initialState: () => {
    return getLocalState('localeState', initialState);
  },
  reducers: {
    setLocale: (
      state: ILocaleState,
      action: PayloadAction<Language | null>
    ) => {
      if (action.payload) state.locale = action.payload;
      else state.locale = initialState.locale;
    },
  },
});

export const { setLocale } = localeSlice.actions;

export default localeSlice.reducer;
