import React from 'react';
import useLocale from '../../../../hooks/useLocale';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RightArrow from '../../../../assets/images/right_arrow_icon.svg';

type ViewAllButtonProps = {
  category?: string | null;
};

function ViewAllBt({ category }: ViewAllButtonProps) {
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [_, path] = useLocale();
  const { t } = useTranslation('home');

  return (
    <div className="view_all">
      <Link to={`${path}/all?category=${category ?? ''}`}>
        <button>
          {t('viewAllCategorie')}
          <img src={RightArrow} alt="shortcut icon" />
        </button>
      </Link>
    </div>
  );
}

export default ViewAllBt;
