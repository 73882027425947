import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { DAppItemProps } from 'core/components';
import { mapDappIdToDapps } from 'utils';
import useBookmarks from 'hooks/queries/useBookmarks';
import useFeatured from 'hooks/queries/useFeatured';
import usePopular from 'hooks/queries/usePopular';
import { selectDapps, selectUser } from 'store/selector';

const useDapps = () => {
  const user = useSelector(selectUser);
  const dapps = useSelector(selectDapps);

  const [bookmarked, setBookmarked] = useState<DAppItemProps[] | null>(null);
  const [featured, setFeatured] = useState<DAppItemProps[] | null>(null);
  const [populars, setPopulars] = useState<DAppItemProps[] | null>(null);

  const { data: bookmarksData } = useBookmarks(user?.userId);
  const { data: featuredData } = useFeatured();
  const { data: popularData } = usePopular();

  const initBookmarks = () => {
    if (!dapps || !bookmarksData) return;
    if (dapps && bookmarksData) {
      const bookmarked: DAppItemProps[] = mapDappIdToDapps(
        dapps,
        bookmarksData.bookmarks
      )?.map(dapp => {
        return {
          bookmark: true,
          dapp: dapp,
        };
      });

      setBookmarked(bookmarked);
    }
  };

  const initFeatured = () => {
    if (!dapps || !featuredData) return;
    if (dapps && featuredData) {
      const featured: DAppItemProps[] = mapDappIdToDapps(
        dapps,
        featuredData?.featured
      )?.map(dapp => {
        return {
          bookmark:
            user && bookmarksData
              ? bookmarksData.bookmarks.includes(dapp?.dappId)
              : undefined,
          dapp: dapp,
        };
      });

      setFeatured(featured);
    }
  };

  const initPopular = () => {
    if (!dapps || !popularData) return;
    if (dapps && popularData) {
      const populars: DAppItemProps[] = mapDappIdToDapps(
        dapps,
        popularData.populars?.dappIds
      )?.map(dapp => {
        return {
          bookmark:
            user && bookmarksData
              ? bookmarksData.bookmarks.includes(dapp?.dappId)
              : undefined,
          dapp: dapp,
        };
      });

      setPopulars(populars);
    }
  };

  useEffect(() => {
    initBookmarks();
  }, [dapps, bookmarksData]);

  useEffect(() => {
    initFeatured();
  }, [dapps, bookmarksData, featuredData, user]);

  useEffect(() => {
    initPopular();
  }, [dapps, bookmarksData, popularData, user]);

  return { bookmarked, featured, populars } as const;
};

export default useDapps;
