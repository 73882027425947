import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDappCategory, IDapp } from 'interfaces/dapp';
import { getLocalState } from 'utils';

export interface IDappListState {
  dapps: IDapp[] | null;
  dappCategories: IDappCategory[] | null;
  version: string | null;
}

export const initialState: IDappListState = getLocalState('dappListState', {
  dapps: null,
  dappCategories: null,
  version: null,
});

export const dappListSlice = createSlice({
  name: 'dappListState',
  initialState: () => {
    return getLocalState('dappListSlice', initialState);
  },
  reducers: {
    setDappListState: (
      state: IDappListState,
      action: PayloadAction<IDappListState>
    ) => {
      state.dapps = action.payload.dapps;
      state.dappCategories = action.payload.dappCategories;
      state.version = action.payload.version;
    },
    resetDappListState: (state: IDappListState) => {
      state.dapps = null;
      state.dappCategories = null;
      state.version = null;
    },
  },
});

export const { setDappListState, resetDappListState } = dappListSlice.actions;

export default dappListSlice.reducer;
