import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './index';
import { IDapp, IDappCategory } from 'interfaces/dapp';
import Fuse from 'fuse.js';
import { IUser } from 'interfaces/user';
import { Language } from 'types/language';

export interface IDappsByCategory {
  [key: string]: IDapp[];
}

export const selectLocale = (state: RootState): Language =>
  state.localeState.locale;

export const selectBaseCurrency = (state: RootState): string =>
  state.baseCurrencyState.baseCurrency;

export const selectUser = (state: RootState): IUser | null =>
  state.userState.user;

export const selectDapps = (state: RootState): IDapp[] | null =>
  state.dappListState.dapps;

export const selectDappCategories = (
  state: RootState
): IDappCategory[] | null => state.dappListState.dappCategories;

export const selectDappCategoryNames = createSelector(
  [selectDappCategories],
  dappCategories => {
    return dappCategories?.map(each => each.dappCatName);
  }
);

export const selectDappsByCategory = createSelector(
  [selectDapps, selectDappCategories],
  (dapps, dappCategories) => {
    const result: IDappsByCategory = {};

    if (dapps && dappCategories) {
      const idx = Fuse.createIndex(['dappCatIds'], dapps);
      const fuse = new Fuse<IDapp>(
        dapps,
        {
          keys: ['dappCatIds'],
          useExtendedSearch: true,
        },
        idx
      );

      dappCategories.map(dappCategory => {
        const dappCatId = dappCategory.dappCatId;
        const dappCatName = dappCategory.dappCatName;

        if (dappCatName === 'All') {
          result[dappCatName] = [...dapps].sort((a, b) =>
            a.order < b.order ? -1 : a.order > b.order ? 1 : 0
          );
          return;
        }

        result[dappCatName] = fuse
          .search(`=${dappCatId}`)
          .map(each => each.item)
          .sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0));
      });
    }
    return result;
  }
);
