import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewAllBt from './viewAllBt/ViewAllBt';

interface CategoryTitleProps {
  icon: string | undefined;
  title: string;
  category: string;
}

function CategoryTitle({ icon, title, category }: CategoryTitleProps) {
  const { t } = useTranslation('home');
  return (
    <div className="category_dapp_title">
      <div className="title">
        {icon && <img src={icon} alt={`${title} img`} />}
        <h2>{title}</h2>
      </div>
      {title && title !== `${t('favorite')}` && (
        <ViewAllBt category={category} />
      )}
    </div>
  );
}

export default CategoryTitle;
