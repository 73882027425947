import React, { useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

type NavigationHeaderProps = {
  title?: string;
  titlePosition?: boolean;
  back?: boolean;
  bg?: boolean;
  invertable?: boolean;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
  children?: React.ReactNode;
  onClickBack?: () => void;
};

gsap.registerPlugin(ScrollTrigger);
function NavigationHeader(props: NavigationHeaderProps) {
  const {
    title,
    titlePosition = false,
    back = false,
    bg = true,
    invertable = true,
    leftActions,
    rightActions,
    children,
    onClickBack,
  } = props;
  const headerRef = useRef(null);
  const [invert, setInvert] = useState<boolean>(false);

  useLayoutEffect(() => {
    const container = document.querySelector('.layout-wrap');
    const header = document.querySelector('.layout-navigation-header');
    if (headerRef.current !== undefined) {
      ScrollTrigger.create({
        trigger: container,
        markers: false,
        start: `top -${header?.clientHeight}px`,
        onEnter: () => {
          setInvert(true);
        },
        onLeaveBack: () => {
          setInvert(false);
        },
      });
    }
  }, [headerRef]);

  return (
    <header
      className={`layout-navigation-header ${bg ? 'default' : 'wt'}`}
      data-invertable={invertable}
      data-invert={invertable && invert}
      ref={headerRef}
    >
      <h2 className="hidden">DAPP Navigation</h2>
      {back && (
        <div className="left-actions">
          <button
            type="button"
            className="icon-buttons back"
            onClick={onClickBack}
          >
            back
          </button>
          {leftActions}
        </div>
      )}
      {children ? (
        <>{children}</>
      ) : (
        title && (
          <button
            type="button"
            className={`navigation-title ${titlePosition ? 'on' : ''}`}
            onClick={() => window.location.reload()}
          >
            <strong>{title}</strong>
          </button>
        )
      )}

      <div className="right-actions">{rightActions}</div>
    </header>
  );
}

export default NavigationHeader;
