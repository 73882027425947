import React, { forwardRef } from 'react';
import CategoryDAppList from './categoryDAppList/CategoryDAppList';
import { CategoriesDAppProps } from './types';
import { useSelector } from 'react-redux';
import { selectDappsByCategory } from 'store/selector';
import CategoryTitle from '../categoryTitle';

function BDUICategoriesDApp({ title, icon, category }: CategoriesDAppProps) {
  // const { t } = useTranslation('home');
  const dappsByCategory = useSelector(selectDappsByCategory);

  return (
    <section className="category_dapp_container inner">
      <CategoryTitle title={title} icon={icon} category={category} />

      {dappsByCategory && dappsByCategory[category] && (
        <CategoryDAppList dapps={dappsByCategory[category]} showRank={false} />
      )}
    </section>
  );
}

export const CategoriesDApp = React.memo(forwardRef(BDUICategoriesDApp));
