import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import useLocale from 'hooks/useLocale';
import { DAppItemProps } from '../types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DappPlaceholder from 'assets/images/dapp.png';

const Recommend: React.FC = props => {
  return <div className="recommend">{props.children}</div>;
};

function BDUIMyDAppItem(props: DAppItemProps) {
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [_, path] = useLocale();
  const { type = 'list', dapp } = props;

  return (
    <Link
      to={`${path}/details/${dapp.dappId}`}
      className="dapp-item"
      rel="noreferrer"
      data-type={type}
    >
      <div className="item-logo">
        <LazyLoadImage
          src={dapp?.dappIconUrl || DappPlaceholder}
          alt={`${dapp?.dappName} logo`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = DappPlaceholder;
          }}
        />
      </div>
      <h3 className="name">{dapp.dappName}</h3>
      {props.featured && <Recommend>{props.featured}</Recommend>}
    </Link>
  );
}
export const MyDAppItem = React.memo(forwardRef(BDUIMyDAppItem));
