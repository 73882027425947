import React, { Fragment } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css/bundle';
import { DAppBannerItemProps } from '../banner/types';

SwiperCore.use([Autoplay, Navigation, Pagination]);

// type EventSliderProps = {
//   banners:
//     | {
//         mediaUrl: string | null;
//         linkUrl: string | null;
//         deeplinkUrl: string | null;
//       }[]
//     | null;
// };
type EventSliderProps = {
  banners: DAppBannerItemProps[];
};

export function EventSlider(props: EventSliderProps) {
  const { banners } = props;

  // const bannersLength = banners?.length;
  // button에 적용됐던 design_v1.5에서 없어짐
  // const onClickDetail = (linkUrl: string | null) => {
  //   if (!linkUrl) return;
  //   window.open(linkUrl, '_blank');
  // };

  return (
    <Fragment>
      {banners && (
        <Swiper
          slidesPerView={1}
          spaceBetween={16}
          autoplay={{ delay: 3000 }}
          loop={false}
          centeredSlides={true}
          // design_v1.5 에서 없어짐
          // pagination={{
          //   clickable: true,
          // }}
          modules={[Pagination]}
          className={banners.length < 2 ? 'event-swiper' : 'event-swiper2'}
        >
          {banners?.map((item, index) => (
            // banners의 갯수가 2보다 작으면 "swiper-slide", 2보다 크면 "swiper-slide2" 적용
            <SwiperSlide key={index}>
              <a href={item.metadata?.linkUri} target="_blank" rel="noreferrer">
                <img
                  src={item.imageUri || ''}
                  alt={item.metadata?.eventType || ''}
                />
                {/*todo: way to sort if link or deeplink*/}
                {/* 아래 button design_v1.5에서 없어짐 */}
                {/* {item.linkUrl && (
                  <button
                    className="event-buttons"
                    onClick={() => onClickDetail(item.linkUrl)}
                  >
                    자세히 <span className="icon-buttons go">go pages</span>
                  </button>
                )} */}
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Fragment>
  );
}
