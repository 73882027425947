import React, { forwardRef } from 'react';
import { DAppItemProps } from './types';
import DappPlaceholder from 'assets/images/dapp.png';

import { useQueryClient } from 'react-query';
import useUpdateBookmark from 'hooks/queries/useUpdateBookmark';
import { useTokenPrice } from 'hooks/queries/useTokenPrice';
// import useLocale from 'hooks/useLocale';

import { useSelector } from 'react-redux';

import { formatFiatPrice } from 'utils';
import { selectBaseCurrency } from '../../../../store/selector';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';

//   setIsMoveHandler: React.Dispatch<React.SetStateAction<boolean>>;
interface DAppItemDetatilsProps {
  item: DAppItemProps;
  setIsMoveHandler: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line
function BDUIDAppItemDetails(props: DAppItemDetatilsProps, ref: any) {
  const { bookmark, dapp } = props.item;
  const setIsMove = props.setIsMoveHandler;
  const { t } = useTranslation('details');

  // const [locale] = useLocale();
  const baseCurrency = useSelector(selectBaseCurrency);

  const queryClient = useQueryClient();

  const { data: assetPrice } = useTokenPrice(
    baseCurrency,
    dapp.dappCoinId ? dapp.dappCoinId.toString() : null
  );

  const bookmarkMutation = useUpdateBookmark();

  const handleClickBookmark = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    bookmarkMutation.mutate(
      {
        action: bookmark ? 'unset' : 'set',
        dappId: dapp?.dappId?.toString(),
      },
      {
        onSettled: () => queryClient.invalidateQueries('/dapps/bookmarks'),
      }
    );
  };

  return (
    <div className="item-details">
      <div className="item-logo">
        <LazyLoadImage
          src={dapp?.dappIconUrl || DappPlaceholder}
          alt={`${dapp?.dappName} logo`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = DappPlaceholder;
          }}
        />
      </div>
      <div className="item-right">
        <div className="item-info">
          <div className="title_wrap">
            <strong className="name">{dapp.dappName}</strong>
          </div>
          {/* design_v1.5 추가 */}
          <div
            className="actions bookmark-actions bookmark_choice"
            data-type={bookmark?.toString()}
          >
            <h3>{t('button.addMyDapp')}</h3>
            {typeof bookmark !== 'undefined' && (
              <button
                type="button"
                className="icon-buttons detail_bookmark"
                value={bookmark?.toString()}
                onClick={handleClickBookmark}
              >
                bookmark on/off
              </button>
            )}
          </div>
          {/*{dapp.dappDesc && dapp.dappDesc[locale] && (*/}
          {/*  <p className="token">{dapp.dappDesc[locale]}</p>*/}
          {/*)}*/}
          {dapp.dappCoinId &&
            typeof assetPrice !== 'undefined' &&
            assetPrice.length > 0 && (
              <p className="description">
                {formatFiatPrice('en-US', baseCurrency, assetPrice[0])}
              </p>
            )}
        </div>
        {/*이동 버튼 */}
        <div className="btn-wrap move_btn_wrap">
          {dapp.dappForumUrl && (
            <button className="btn-common wt">{t('button.goForum')}</button>
          )}
          {dapp.dappUrl && (
            <button
              className="btn-common purple"
              onClick={() => setIsMove(true)}
            >
              {t('button.goDapp')}
            </button>
          )}
        </div>
      </div>
      {/* design_v1.5 아이콘 변경 및 레이아웃 위치 이동 */}
      {/* <div className="actions bookmark-actions">
        {typeof bookmark !== 'undefined' && (
        <button
          type="button"
          className="icon-buttons bookmark"
          value={bookmark?.toString()}
          onClick={handleClickBookmark}
        >
          bookmark on/off
        </button>
        )}
      </div> */}
    </div>
  );
}
export const DAppItemDetails = React.memo(forwardRef(BDUIDAppItemDetails));
