import React, { forwardRef } from 'react';
import { DAppItemProps } from './types';
import { useQueryClient } from 'react-query';
import useUpdateBookmark from 'hooks/queries/useUpdateBookmark';
import DappPlaceholder from 'assets/images/dapp.png';
// eslint-disable-next-line
import { dispatchGACategory, dispatchGAExecute } from 'lib/firebase';
import { useSelector } from 'react-redux';
import { selectDappCategories, selectUser } from 'store/selector';

import { ChainType } from '../../../constants';
import { Link } from 'react-router-dom';
import useLocale from 'hooks/useLocale';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Recommend: React.FC = props => {
  return <div className="recommend">{props.children}</div>;
};

const Rank: React.FC = props => {
  return <h2 className="rank">{props.children}</h2>;
};

// eslint-disable-next-line
function BDUIDAppItem(props: DAppItemProps, ref: any) {
  const [locale, path] = useLocale();

  const dappCategories = useSelector(selectDappCategories);
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();

  const { type = 'list', bookmark, dapp, onClick } = props;

  const bookmarkMutation = useUpdateBookmark();
  const handleClickBookmark = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    bookmarkMutation.mutate(
      {
        action: bookmark ? 'unset' : 'set',
        dappId: dapp?.dappId?.toString(),
      },
      {
        onSettled: () => queryClient.invalidateQueries('/dapps/bookmarks'),
      }
    );
  };

  const handleLinkClick = async () => {
    onClick && onClick();

    await dispatchGAExecute(user, dapp.dappId.toString());

    if (dappCategories) {
      dappCategories
        .filter(item => dapp.dappCatIds.includes(item.dappCatId.toString()))
        .map(async each => {
          await dispatchGACategory(user, each.dappCatName);
        });
    }
  };

  return (
    <Link
      to={`${path}/details/${dapp.dappId}`}
      className="dapp-item"
      rel="noreferrer"
      data-type={type}
      ref={ref}
      onClick={handleLinkClick}
    >
      <figure>
        <div className="item-wrap">
          <div className="item-logo">
            <LazyLoadImage
              src={dapp?.dappIconUrl || DappPlaceholder}
              alt={`${dapp?.dappName} logo`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = DappPlaceholder;
              }}
            />
          </div>
          {props.rank && <Rank>{props.rank}</Rank>}
          <figcaption className="item-info">
            <div className="info">
              <div className="name-wrap">
                {props.featured && <Recommend>{props.featured}</Recommend>}
                <strong className="name">{dapp?.dappName}</strong>
                <div className="mainnet-list">
                  {dapp?.dappMainnetCoinIds.map(each => (
                    <div
                      key={`idx-${each}`}
                      className={`icons-mainnet icons-${ChainType.getNetworkNameByCoinId(
                        Number(each)
                      ).toLowerCase()}`}
                    />
                  ))}
                </div>
                {dapp.dappDesc && dapp.dappDesc[locale] !== 'null' && (
                  <p
                    className={props.rank ? 'rank_description' : 'description'}
                  >
                    {dapp?.dappDesc[locale]}
                  </p>
                )}
              </div>
              {/*<p className="value">*/}
              {/*  <span className="amount">{app.amount}</span>*/}
              {/*  <span className="rate" data-down={app.rate.charAt(0) === "-"}>*/}
              {/*    {app.rate}*/}
              {/*  </span>*/}
              {/*</p>*/}
            </div>
          </figcaption>
        </div>
        {typeof bookmark !== 'undefined' && (
          <div className="actions">
            <button
              type="button"
              className="icon-buttons bookmark"
              value={bookmark?.toString()}
              onClick={handleClickBookmark}
            >
              bookmark on/off
            </button>
          </div>
        )}
      </figure>
    </Link>
  );
}
export const DAppItem = React.memo(forwardRef(BDUIDAppItem));
